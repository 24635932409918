<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>SOCIAL MEDIA</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.socialMediaAddModal.show()" text="Add"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
        </div>

        <custom-vue-table :show-search-box="false" :fields="fields" :url="listUrl" :per-page="10"
                          class="d-sm-none-lg-block" ref="table" :extra-params="{ext_search}">

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>

                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>

        </custom-vue-table>
        <social-media-mobile-view :ext_search="ext_search" class="d-lg-none" @viewDetails="viewDetails"
                                  @setDelete="setDelete" ref="socialMediaMobileView"></social-media-mobile-view>
        <modal title="Add Social Media" ref="socialMediaAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddSocialMedia @success="formSuccess"></AddSocialMedia>
        </modal>

        <modal title="Edit Social Media" ref="socialMediaEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditSocialMedia :initial-data="editingItem" @success="formSuccess"></EditSocialMedia>
        </modal>

        <delete-modal ref="socialMediaDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Social Media <b v-html="deletingItem && deletingItem.site_name"></b>. Are you
                sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.site_name"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddSocialMedia from './AddSocialMedia';
import EditSocialMedia from './EditSocialMedia';
import SocialMediaMobileView from '@/views/cms/home/social-media/SocialMediaMobileView';

export default {
    name : 'SocialMedia',

    components : { SocialMediaMobileView, AddSocialMedia, EditSocialMedia },

    data () {
        return {
            ext_search : '',
            listUrl    : urls.cms.socialMedia.list,
            deleteUrl  : urls.cms.socialMedia.delete,

            editingItem  : null,
            deletingItem : null,

            fields : [
                {
                    name      : 'name',
                    sortField : '',
                    title     : 'Social Media'
                },
                {
                    name      : 'site_map_type',
                    sortField : '',
                    title     : 'Social Media Type'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Created At'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewDetails (id) {
            this.$router.push('/cms/social-media/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.socialMediaAddModal.close();
            refs.socialMediaEditModal.close();
            refs.table.refreshTable();
            refs.socialMediaMobileView.loadData();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.socialMediaEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.socialMediaDeleteModal.show();
        },

        deleteComplete (response) {
            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted!', 'Success', { type : 'success' }
                );
            }
            this.deletingItem = null;
            this.$refs.socialMediaDeleteModal.close();
            this.$refs.table.refreshTable();
            this.$refs.socialMediaMobileView.loadData();
        }

    }
};
</script>

<style scoped>

</style>
